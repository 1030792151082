import {
    ThemeProvider as MuiThemeProvider,
    createTheme,
} from "@mui/material/styles";
import { TextFieldVariants } from "@mui/material/TextField";
import classNames from "classnames";
import React, { ReactElement, useCallback, useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { NumericFormat, PatternFormat } from "react-number-format";
import { ThemeContext, ThemeProvider } from "styled-components";

import evaluteVisibilityFromDependencies from "utils/evaluteVisibilityFromDependencies";
import generateValidationRules from "utils/generateValidationRules";

import {
    AdornmentWrapper,
    ErrorIcon,
    ErrorText,
    FieldWrapper,
    StyledTextField,
} from "./TextField.styled";
import TextFieldProps from "./TextFieldProps";

const TextField = ({
    id,
    identifier,
    label,
    placeHolder,
    focused,
    disabled,
    type = "text",
    predefinedValue,
    autoComplete,
    displayOption = "Full",
    inputFormatNumber = false,
    inputFormatPattern,
    description,
    elementName,
    className,
    component,
    validators,
    control,
    endAdornment,
    dependencies,
    inputMode = "text",
    pattern,
    size = "medium",
    onChange,
    setValue,
    onFocus,
    tabIndex,
}: TextFieldProps): ReactElement => {
    const formContext = useFormContext();
    const themeContext = useContext(ThemeContext);
    const unregister = formContext?.unregister || undefined;

    const isNumeric = component === "NumberElementBlock" || type === "number";
    const classes = classNames(
        validators?.map((validator) => {
            return validator?.model?.validationCssClass || "";
        }),
    );

    const rules =
        validators && validators?.length > 0
            ? generateValidationRules(validators)
            : undefined;

    const visibleFromDependencies =
        dependencies && control
            ? evaluteVisibilityFromDependencies(dependencies, control)
            : true;

    useEffect(() => {
        if (elementName && setValue && unregister)
            if (visibleFromDependencies) setValue(elementName, predefinedValue);
            else unregister(elementName);
    }, [
        elementName,
        predefinedValue,
        setValue,
        unregister,
        visibleFromDependencies,
    ]);

    // Set the correct types and input modes for the input
    if (isNumeric) {
        inputMode = "numeric";
        type = "text";
        pattern = inputFormatNumber ? "^[0-9 ]*$" : "^[0-9]*$";
    }

    if (validators?.some((validator) => validator.type === "EmailValidator")) {
        type = "email";
        inputMode = "email";
    }

    const getAdornment = useCallback(
        (error) =>
            (
                <AdornmentWrapper>
                    {error && <ErrorIcon icon="error28" />}
                    {endAdornment}
                </AdornmentWrapper>
            ) || null,
        [endAdornment],
    );

    return (
        <MuiThemeProvider theme={createTheme()}>
            <ThemeProvider
                theme={{
                    ...themeContext,
                    displayOption,
                    size,
                }}
            >
                {visibleFromDependencies && (
                    <Controller
                        name={elementName || ""}
                        control={control}
                        defaultValue={predefinedValue || ""}
                        rules={rules}
                        render={({
                            field: { ref, ...field },
                            fieldState: { error },
                        }) => {
                            const elementProps = {
                                className: classes,
                                id: id || identifier,
                                inputRef: ref,
                                label: label,
                                placeholder: placeHolder,
                                focused: focused,
                                error: !!error,
                                disabled: disabled,
                                autoComplete: autoComplete,
                                helperText: description,
                                inputProps: {
                                    inputMode: inputMode,
                                    pattern: pattern,
                                    tabIndex: tabIndex,
                                },
                                variant: "outlined" as TextFieldVariants,
                                type: type,
                                InputProps: {
                                    endAdornment: getAdornment(error),
                                },
                                InputLabelProps: {
                                    shrink: field.value ? true : undefined,
                                    className: endAdornment
                                        ? "Mui-adornment"
                                        : "",
                                },
                                onFocus: (
                                    event: React.FocusEvent<HTMLInputElement>,
                                ) => {
                                    if (onFocus) {
                                        onFocus(event);
                                    }
                                },
                                onChange: (
                                    event: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                    field.onChange(event);

                                    if (onChange) {
                                        onChange(event, event.target.value);
                                    }

                                    if (isNumeric && setValue && elementName)
                                        setValue(
                                            elementName,
                                            event.target.value.replace(
                                                /\D/g,
                                                "",
                                            ),
                                        );
                                },
                            };

                            return (
                                <FieldWrapper className={className}>
                                    {error && (
                                        <ErrorText>
                                            <span>{error.message}</span>
                                        </ErrorText>
                                    )}
                                    {!inputFormatNumber &&
                                        !inputFormatPattern && (
                                            <StyledTextField
                                                {...field}
                                                {...elementProps}
                                            />
                                        )}
                                    {inputFormatNumber &&
                                        !inputFormatPattern && (
                                            <NumericFormat
                                                {...field}
                                                {...elementProps}
                                                customInput={StyledTextField}
                                                type="text"
                                                thousandSeparator=" "
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>,
                                                ) => {
                                                    if (onChange) {
                                                        onChange(
                                                            event,
                                                            event.target.value,
                                                        );
                                                    }
                                                }}
                                                onValueChange={(
                                                    formatValues,
                                                ) => {
                                                    field.onChange(
                                                        formatValues.value
                                                            ? Number(
                                                                  formatValues.value,
                                                              )
                                                            : "",
                                                    );
                                                }}
                                            />
                                        )}
                                    {inputFormatPattern && (
                                        <PatternFormat
                                            {...elementProps}
                                            {...field}
                                            customInput={StyledTextField}
                                            format={inputFormatPattern}
                                            type="text"
                                            onChange={(event) => {
                                                if (onChange) {
                                                    onChange(
                                                        event,
                                                        event.target.value,
                                                    );
                                                }
                                            }}
                                            onValueChange={(formatValues) => {
                                                field.onChange(
                                                    formatValues.formattedValue,
                                                );
                                            }}
                                        />
                                    )}
                                </FieldWrapper>
                            );
                        }}
                    />
                )}
            </ThemeProvider>
        </MuiThemeProvider>
    );
};

export default React.memo(TextField);
