import { ValidationRule } from "react-hook-form";

import EpiValidator from "types/EpiValidator";

const generateValidationRules = (validationRules: EpiValidator[]) => {
    let rules = { validate: {} } as Rules;

    validationRules.forEach((validationRule: EpiValidator, index: number) => {
        switch (validationRule.type) {
            case "RequiredValidator":
                rules = {
                    ...rules,
                    required: {
                        value: true,
                        message:
                            validationRule?.model?.message ||
                            "This field is required",
                    },
                };
                break;
            case "MaxLengthValidator":
                rules = {
                    ...rules,
                    maxLength: {
                        value: validationRule?.model?.maxLength || 100,
                        message:
                            validationRule?.model?.message ||
                            "This field has a too long value",
                    },
                };
                break;
            case "AllowedExtensionsValidator":
                rules = {
                    ...rules,
                    validate: {
                        ...rules.validate,
                        [`${validationRule.type}-${index}`]: (
                            FileList: any[], //eslint-disable-line @typescript-eslint/no-explicit-any
                        ): string | boolean => {
                            if (FileList && FileList.length > 0) {
                                const files = Array.from(FileList);
                                const fallbackExtensions = [
                                    "jpg",
                                    "jpeg",
                                    "png",
                                    "gif",
                                    "bmp",
                                    "webp",
                                    "svg",
                                    "doc",
                                    "docx",
                                    "xls",
                                    "xlsx",
                                    "pdf",
                                ];
                                const allowedExtensions = validationRule?.model
                                    ?.accept
                                    ? validationRule.model.accept
                                          .replaceAll(".", "")
                                          .split(",")
                                    : fallbackExtensions;
                                const filesExtensions = files.map((file) =>
                                    file.name.split(".").pop().toLowerCase(),
                                );
                                const isValid = filesExtensions.every(
                                    (extension) =>
                                        allowedExtensions.includes(extension),
                                );
                                if (!isValid) {
                                    return (
                                        validationRule?.model?.message ||
                                        "This field is invalid"
                                    );
                                }
                            }
                            return true;
                        },
                    },
                };
                break;
            case "MaxFileSizeValidator":
                rules = {
                    ...rules,
                    validate: {
                        ...rules.validate,
                        [`${validationRule.type}-${index}`]: (
                            FileList: any[], //eslint-disable-line @typescript-eslint/no-explicit-any
                        ): string | boolean => {
                            if (FileList && FileList.length > 0) {
                                const files = Array.from(FileList);
                                const fallbackSizeInBytes = 104857600;
                                const allowedSizeInBytes =
                                    validationRule?.model?.sizeInBytes ||
                                    fallbackSizeInBytes;
                                const isValid = files.every(
                                    (file) =>
                                        Number(file?.size) < allowedSizeInBytes,
                                );
                                if (!isValid) {
                                    return (
                                        validationRule?.model?.message ||
                                        "This field is invalid"
                                    );
                                }
                            }
                            return true;
                        },
                    },
                };
                break;
            default:
                if (validationRule?.model?.jsPattern) {
                    rules = {
                        ...rules,
                        pattern: {
                            value: new RegExp(validationRule?.model?.jsPattern),
                            message:
                                validationRule?.model?.message ||
                                "This field is invalid",
                        },
                    };
                }
                break;
        }
    });
    return rules;
};

interface Rules {
    required?: ValidationRule<boolean>;
    maxLength?: ValidationRule<number>;
    pattern?: ValidationRule<RegExp>;
    validate: {
        [key: string]: (value: any) => string | boolean; //eslint-disable-line @typescript-eslint/no-explicit-any
    };
}

export default generateValidationRules;
